
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Field } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "house-insurance-package",
  components: { Field },
  setup() {
    const { useReusableNavigation } = ResusableFunctions();
    const housingPackages = ref([{ name: "", publicId: "" }]);

    // Function to get hong packages
    const getHousingPackages = async () => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.HOME_INSURANCE_PACKAGES_ROUTE)
          .then(({ data }) => {
            housingPackages.value = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    };

    const getContents = (value: string) => {
      let name = "";
      let content = "";
      let image = "";
      let route = "";

      switch (value) {
        case "Homeowner":
          name = "Home Owner";
          content = "I own the bulding to be insured.";
          image = "/media/icons/duotone/Home/Building.svg";
          route = "home-owner-house-insurance-package";
          break;
        case "Householder":
          name = "Householder";
          content = "I do not own the building to be insured.";
          image = "/media/icons/duotone/Clothes/Briefcase.svg";
          route = "house-holder-house-insurance-package";
          break;
        case "Personal Accident":
          name = "Personal Accident";
          content =
            "This insurance provides financial compensation for the listed benefits as a result of an accident involving the insured person(s).";
          image = "/media/icons/duotone/Clothes/Briefcase.svg";
          route = "personal-accident-house-insurance-package";
          break;
        case "Third Party liability":
          name = "Third Party liability";
          content = "This insurance provides.";
          image = "/media/icons/duotone/Shopping/Barcode.svg";
          route = "third-party-liability-house-insurance-package";
          break;
        default:
          name = "Unavailable";
          content = "Businses type not available.";
          image = "/media/icons/duotone/Home/Building.svg";
          route = "";
      }

      return {
        name,
        content,
        image,
        route,
      };
    };

    onMounted(() => {
      getHousingPackages();
      setCurrentPageBreadcrumbs("Home Insurance ", ["Insurance Packages"]);
    });

    return { housingPackages, getContents, useReusableNavigation };
  },
  created() {
    //Set page title
    document.title = "Home Insurance Package | " + process.env.VUE_APP_TITLE;
  },
});
